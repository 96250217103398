const searchBusinessScreenView = "search-business";
let emailAddress;

const goToSpecificScreen = (screenId) => {
    heyflow.$store.commit("moveToScreen", screenId);
};

function getClickedCompany(event, data) {
    const clickedElement = event.target;

    if (clickedElement.classList.contains('card') && clickedElement.id) {
        return data.filter(item => item.id === clickedElement.id);
    }

    const parentElement = clickedElement.parentElement;
    if (parentElement) {
        const parentCard = parentElement.classList.contains('card') ? parentElement: parentElement.parentElement;
        if (parentCard && parentCard.id) {
            return data.filter(item => item.id === parentCard.id);
        }
    }

    console.log('No data found');
    return null;
}

function updateInputs(companyData) {
    const loader = document.querySelector('.circle-loader')

    const dataInput = document.querySelector('[data-label="provider_id"]');
    const nameInput = document.querySelector('[data-label="business_name"]');
    const cityInput = document.querySelector('[data-label="business_city"]');
    const zipCodeInput = document.querySelector('[data-label="business_zip_code"]');
    const streetAddressInput = document.querySelector('[data-label="business_street_address"]');
    const houseNumberInput = document.querySelector('[data-label="business_house_number"]');

    if (companyData && companyData.length > 0) {
        dataInput.value = companyData[0].id;
        nameInput.value = companyData[0].name;
        cityInput.value = companyData[0].city;
        zipCodeInput.value = companyData[0].zip_code;
        streetAddressInput.value = companyData[0].street_address;
        houseNumberInput.value = companyData[0].house_number;

        document.querySelector('[name="search-business"] button.submit').click(); // Trigger submit button click
        loader.style.display = 'inline-block'
    }
}

function renderTable(data) {
    const wrapper = document.querySelector('form');
    const cardContainer = document.querySelector('.card-container') || document.createElement('div');
    const dataInput = document.querySelector('[data-label="provider_id"]');
    const addCompanyButton = document.querySelector('[name="search-business"] button.link');

    cardContainer.classList.add('card-container');
    cardContainer.innerHTML = '';

    if (!wrapper) {
        console.error('Form wrapper not found.');
        return;
    }

    if (!cardContainer) {
        console.error('Card container not found.');
        return;
    }

    cardContainer.addEventListener('click', (event) => {
        const companyData = getClickedCompany(event, data);
        updateInputs(companyData);
    });

    data.forEach(item => {
        const card = document.createElement('div');
        card.classList.add('card');
        card.id = item.id;

        const cardContent = document.createElement('div');
        cardContent.classList.add('card-content');

        var propertyElement = document.createElement('p');
        propertyElement.classList.add("business_name");
        propertyElement.textContent = item["name"]
        cardContent.appendChild(propertyElement);

        propertyElement = document.createElement('p');
        propertyElement.classList.add("business_description");
        propertyElement.textContent = `${item["street_address"]} ${item["house_number"]}`
        cardContent.appendChild(propertyElement);

        propertyElement = document.createElement('p');
        propertyElement.classList.add("business_description");
        propertyElement.textContent = `${item["zip_code"]} ${item["city"]}`
        cardContent.appendChild(propertyElement);

        card.appendChild(cardContent);
        cardContainer.appendChild(card);
    });

    const addCompany = document.createElement('div');
    addCompany.onclick = () => addCompanyButton.click()
    addCompany.classList.add('card');
    addCompany.id = 'addCompanyAdd';

    const cardContent = document.createElement('div');
    cardContent.classList.add('card-content');

    const propertyElement = document.createElement('p');
    propertyElement.classList.add('business_name');
    propertyElement.textContent = `Manuell Hinzufugen`;
    cardContent.appendChild(propertyElement);

    addCompany.appendChild(cardContent);
    cardContainer.appendChild(addCompany);

    wrapper.appendChild(cardContainer);
}

function handleScreenView(event) {
    console.log('heyflow screen view:', event.detail.screenName);
    const cardContainer = document.querySelector('.card-container');
    const loader = document.querySelector('.circle-loader')
    if (event.detail.screenName !== searchBusinessScreenView) {
        if (cardContainer) cardContainer.style.display = 'none';
    } else {
        if (cardContainer) cardContainer.style.display = 'grid';
        if (loader) loader.style.display = 'none';
    }
}

function handleEmailInput(event) {
    emailAddress = event.target.value;
}

function handleSearchInput(event) {
    const searchQuery = event.target.value;
    const domain_url = 'https://app.fincompare.de/api/v1/company?q=' + searchQuery;
    const dev_url = `https://corsproxy.io/?${encodeURIComponent('https://app.fincompare.de/api/v1/company?q=' + searchQuery)}`;

    fetch(domain_url)
    .then(response => response.json())
    .then(data => renderTable(data))
    .catch(
        error => fetch(dev_url)
        .then(response => response.json())
        .then(data => renderTable(data))
        .catch(error => console.error('Error:', error))
    );
}

window.addEventListener('heyflow-screen-view', handleScreenView);
window.addEventListener('heyflow-init', () => {
    // const emailInput = document.querySelector('[data-label="E-Mail Adresse"]');
    // if (!emailInput) {
    //     console.error('Email input not found.');
    //     return;
    // }
    // emailInput.addEventListener('input', handleEmailInput);

    const searchInput = document.querySelector('[data-label="Unternehmensname"]');
    if (!searchInput) {
        console.error('Search input not found.');
        return;
    }
    searchInput.addEventListener('input', handleSearchInput);
});